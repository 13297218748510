import isEmpty from 'lodash/isEmpty';
import { parse } from 'path-browserify';
import { getAlfaPackages } from '~/server/helpers/get-alfa-packages';
import { IDictionaryManifest } from '~/models';

const alfaPackages = getAlfaPackages();

/**
 * Функция возвращает объект с манифестами и версиями пакетов
 * @param r вебпак контекст с манифестами
 * @param packagesVersions версии пакетов
 */
export const importManifests = (
    r: ReturnType<typeof require.context>,
    packagesVersions: Record<string, string>
): IDictionaryManifest => {
    let manifests = {};
    /** добавляем в манифесты версии пакетов */
    r.keys().forEach(key => {
        const { dir } = parse(key);
        const packageName = dir.replace('./', '');

        const manifest = Object.entries(r(key).manifest as IDictionaryManifest).reduce(
            (acc, [widgetName, widgetManifest]) => {
                acc[widgetName] = {
                    ...widgetManifest
                };

                if (!isEmpty(packagesVersions)) {
                    acc[widgetName].version = packagesVersions[dir];
                }

                if (alfaPackages[packageName]) {
                    acc[widgetName].isDirectDependency = true;
                }

                return acc;
            },
            {} as IDictionaryManifest
        );
        if (!isEmpty(manifest)) {
            manifests = { ...manifests, ...manifest };
        }
    });

    return manifests;
};
