import { parse } from 'path-browserify';

/** функция возвращает объект с версиями пакетов
 * @param r вебпак контекст. содержит все файлы package.json из пакетов
 */
export const importPackageJsons = (
    r: ReturnType<typeof require.context>
): Record<string, string> => {
    return r.keys().reduce((acc, key) => {
        const { dir } = parse(key);

        return {
            ...acc,
            [dir]: r(key).version
        };
    }, {});
};
