import { IDictionaryManifest } from '~/models';

import { importPackageJsons } from '~/server/helpers/import-package-jsons';
import { importManifests } from '~/server/helpers/import-manifests';
import { alfaBankCoreManifests, alfaBankCorePackages } from './core-imports';

let widgetsManifests: IDictionaryManifest = {};

let packagesVersions: Record<string, string> = {};

export const initWidgets = (
    manifestsContext: Array<ReturnType<typeof require.context>>,
    packagesContext: Array<ReturnType<typeof require.context>> = []
) => {
    packagesVersions = [alfaBankCorePackages, ...packagesContext].reduce(
        (acc, context) => ({ ...acc, ...importPackageJsons(context) }),
        {}
    );

    widgetsManifests = [alfaBankCoreManifests, ...manifestsContext].reduce(
        (acc, context) => ({ ...acc, ...importManifests(context, packagesVersions) }),
        {}
    );
};

export const getWidgetsManifests = () => widgetsManifests;
