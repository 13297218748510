/* eslint-disable sonarjs/no-duplicate-string */
/** Импорт пакетов @alfa-bank для ядра (зависимости, которые не захоистились в корневой node_modules).
 * */

export const alfaBankCoreManifests = require.context(
    '@alfa-bank',
    true,
    /\.\/[\da-z-]+\/widgets\.manifest\.ts$/
);

export const alfaBankCoreSchemas = require.context(
    '@alfa-bank',
    true,
    /\.\/[\da-z-]+\/schema\/.+\.json$/
);

/** пакеты из binary */
export const alfaBankCorePackages = require.context(
    '@alfa-bank',
    true,
    /\.\/[\da-z-]+\/package\.json$/
);
